import { defineMessages } from "react-intl";

export default defineMessages({
  amortization_flow_amortize_plan: {
    id: "amortization_flow_amortize_plan",
    defaultMessage: "Amortizar plan"
  },
  amortization_flow_advance_payment: {
    id: "amortization_flow_advance_payment",
    defaultMessage: "Adelantar pago"
  },
  amortization_flow_amortize_plan_description: {
    id: "amortization_flow_amortize_plan_description",
    defaultMessage:
      "Siempre libre y sin ataduras. Finaliza tu plan de pago cuando quieras."
  },
  amortization_flow_payment_summary: {
    id: "amortization_flow_payment_summary",
    defaultMessage: "Resumen del pago"
  },
  amortization_flow_total_unpaid_instalments: {
    id: "amortization_flow_total_unpaid_instalments",
    defaultMessage: "Principal restante de la compra"
  },
  amortization_flow_instalment_fees: {
    id: "amortization_flow_instalment_fees",
    defaultMessage: "Costes fijos"
  },
  amortization_flow_tooltip_title: {
    id: "amortization_flow_tooltip_title",
    defaultMessage: "¿Qué es?"
  },
  amortization_flow_tooltip_subtitle: {
    id: "amortization_flow_tooltip_subtitle",
    defaultMessage:
      "Importe de tu compra que falta por pagar. Sin costes de servicio."
  },
  amortization_flow_total: {
    id: "amortization_flow_total",
    defaultMessage: "Total"
  },
  amortization_flow_recipient: {
    id: "amortization_flow_recipient",
    defaultMessage: "Beneficiario"
  },
  amortization_flow_reference: {
    id: "amortization_flow_reference",
    defaultMessage: "Concepto"
  },
  amortization_flow_iban: {
    id: "amortization_flow_iban",
    defaultMessage: "IBAN"
  },
  amortization_flow_amount: {
    id: "amortization_flow_amount",
    defaultMessage: "Importe"
  },
  amortization_flow_choose_a_bank_account: {
    id: "amortization_flow_choose_a_bank_account",
    defaultMessage: "Elige la cuenta bancaria que te sea más cómoda."
  },
  pay_with_card: {
    id: "pay_with_card",
    defaultMessage: "Paga con tarjeta"
  },
  amortization_flow_payment_plan_finished: {
    id: "amortization_flow_payment_plan_finished",
    defaultMessage: "¡Plan finalizado con éxito!"
  },
  by_wire_transfer: {
    id: "by_wire_transfer",
    defaultMessage: "o transferencia bancaria"
  },
  amortization_flow_payment_by_transfer: {
    id: "amortization_flow_payment_by_transfer",
    defaultMessage: "Pago por transferencia"
  },
  amortization_flow_payment_by_transfer_description: {
    id: "amortization_flow_payment_by_transfer_description",
    defaultMessage:
      "Una vez realizada la transferencia, te enviaremos un email confirmando el pago en 1-2 días laborables."
  },
  success_plan_modal_title: {
    id: "success_plan_modal_title",
    defaultMessage: "¡Plan finalizado!"
  },
  success_plan_modal_first_paragraph: {
    id: "success_plan_modal_first_paragraph",
    defaultMessage:
      "Te hacemos tan fácil terminar un plan de pago como ha sido empezarlo."
  },
  success_plan_modal_second_paragraph: {
    id: "success_plan_modal_second_paragraph",
    defaultMessage: "Disfruta comprando sin ataduras."
  },
  success_plan_modal_close: {
    id: "success_plan_modal_close",
    defaultMessage: "Cerrar"
  },
  general_date: {
    id: "general.date",
    defaultMessage: "{date}"
  },
  general_greeting: {
    id: "general.greeting",
    defaultMessage: "Hola"
  },
  general_cookies_banner_message: {
    id: "cookies_banner.message",
    defaultMessage: "Usamos cookies para ofrecerte una mejor experiencia"
  },
  general_cookies_banner_more_info: {
    id: "cookies_banner.more_info",
    defaultMessage: "Saber más"
  },
  general_cookies_banner_confirm: {
    id: "cookies_banner.confirm",
    defaultMessage: "Entiendo"
  },
  general_navigation_dashboard: {
    id: "general.navigation_dashboard",
    defaultMessage: "Tu espacio"
  },
  general_navigation_orders: {
    id: "general.navigation_orders",
    defaultMessage: "Compras"
  },
  general_navigation_cards: {
    id: "general.navigation_cards",
    defaultMessage: "Tarjetas"
  },
  general_navigation_profile: {
    id: "general.navigation_profile",
    defaultMessage: "Perfil"
  },
  general_navigation_help: {
    id: "general.navigation_help",
    defaultMessage: "Ayuda"
  },
  general_navigation_logout: {
    id: "general.navigation_logout",
    defaultMessage: "Cerrar sesión"
  },
  general_navigation_login: {
    id: "general.navigation_login",
    defaultMessage: "Entrar en mi cuenta"
  },
  general_navigation_detail: {
    id: "general.navigation_detail",
    defaultMessage: "Detalle"
  },
  general_navigation_next: {
    id: "general.navigation_next",
    defaultMessage: "Siguiente"
  },
  general_navigation_finish: {
    id: "general.general_navigation_finish",
    defaultMessage: "Finalizar"
  },
  general_navigation_back: {
    id: "general.navigation_back",
    defaultMessage: "Atrás"
  },
  general_navigation_come_back: {
    id: "general.navigation_come_back",
    defaultMessage: "Volver"
  },
  general_navigation_back_to_order: {
    id: "general.navigation_back_to_order",
    defaultMessage: "Volver a la compra"
  },
  general_navigation_beta_label: {
    id: "general.general_navigation_beta_label",
    defaultMessage: "Nuevo"
  },
  general_payment_card_mask: {
    id: "general.payment_card_mask",
    defaultMessage: "···· {lastFour}"
  },
  general_payment_card_expiry_date: {
    id: "general.payment_card_expiry_date",
    defaultMessage: "{expiryMonth}/{expiryYear}"
  },
  general_payment_card_expired: {
    id: "general.payment_card_expired",
    defaultMessage: "Tarjeta caducada"
  },
  general_payment_card_expiring_soon: {
    id: "general.payment_card_expiring_soon",
    defaultMessage: "Caducará pronto"
  },
  general_page_title_dashboard: {
    id: "general.page_title.dashboard",
    defaultMessage: "Tu espacio"
  },
  general_page_title_profile: {
    id: "general.page_title.profile",
    defaultMessage: "Tu perfil"
  },
  general_page_title_payment_cards: {
    id: "general.page_title.payment_cards",
    defaultMessage: "Tus tarjetas"
  },
  general_page_title_help: {
    id: "general.page_title.help",
    defaultMessage: "Ayuda"
  },
  general_page_subtitle_help: {
    id: "general.page_subtitle_help",
    defaultMessage: "¿Tienes alguna duda? Pregúntanos"
  },
  faqs_title: {
    id: "help.faqs.title",
    defaultMessage: "Preguntas frecuentes"
  },
  faqs_description: {
    id: "help.faqs.description",
    defaultMessage:
      "Aquí encontrarás respuestas a las preguntas más habituales."
  },
  faqs_button: {
    id: "help.faqs.button",
    defaultMessage: "Consultar"
  },
  faqs_button_rentik: {
    id: "help.faqs.button_rentik",
    defaultMessage: "Preguntas frecuentes"
  },
  email_title: {
    id: "help.email.title",
    defaultMessage: "Correo"
  },
  email_description: {
    id: "help.email.description",
    defaultMessage: "Escríbenos y te responderemos lo antes posible."
  },
  phone_title: {
    id: "help.phone.title",
    defaultMessage: "Teléfono"
  },
  phone_description: {
    id: "help.phone.description",
    defaultMessage:
      "Llámanos. Estamos de Lunes a Viernes, de 9 a 14 y de 15 a 18."
  },
  profile_personal_info: {
    id: "profile.personal.info",
    defaultMessage: "Datos personales"
  },
  profile_info_name: {
    id: "profile.info.name",
    defaultMessage: "Nombre"
  },
  profile_info_nin: {
    id: "profile.info.nin",
    defaultMessage: "DNI"
  },
  profile_selectors_title_email: {
    id: "profile_selectors_title_email",
    defaultMessage: "Email"
  },
  profile_selectors_title_phone: {
    id: "profile_selectors_title_phone",
    defaultMessage: "Móvil"
  },
  profile_selectors_emails: {
    id: "profile.selectors.email",
    defaultMessage: "Correos electrónicos"
  },
  profile_selectors_phones: {
    id: "profile.selectors.phones",
    defaultMessage: "Teléfonos"
  },
  profile_selectors_start_validation: {
    id: "profile.selectors.start_validation",
    defaultMessage: "Iniciar verificación"
  },
  profile_selectors_inprogress_validation: {
    id: "profile.selectors.inprogress_validation",
    defaultMessage: "Verificación en progreso"
  },
  profile_selectors_tooltip_email: {
    id: "profile_selectors_tooltip_email",
    defaultMessage: "Necesitamos al menos un email validado en tu cuenta"
  },
  profile_selectors_tooltip_phone: {
    id: "profile_selectors_tooltip_phone",
    defaultMessage: "Necesitamos al menos un móvil validado en tu cuenta"
  },
  profile_add_selector_phone: {
    id: "profile.add_selector_phone",
    defaultMessage: "Añadir número"
  },
  profile_add_selector_email: {
    id: "profile.add_selector_email",
    defaultMessage: "Añadir email"
  },
  profile_create_selector_form: {
    id: "profile.create_selector_form",
    defaultMessage: "Validar"
  },
  profile_create_selector_form_placeholder: {
    id: "profile.create_selector_form_placeholder",
    defaultMessage: "Móvil o Correo electrónico"
  },
  profile_create_selector_form_placeholder_phone: {
    id: "profile.profile_create_selector_form_placeholder_phone",
    defaultMessage: "Nuevo teléfono"
  },
  profile_create_selector_form_placeholder_email: {
    id: "profile.profile_create_selector_form_placeholder_email",
    defaultMessage: "Nuevo email"
  },
  profile_close_selector_form: {
    id: "profile.close_selector_form",
    defaultMessage: "Cancelar"
  },
  profile_success_selector_email: {
    id: "profile.success_selector_email",
    defaultMessage:
      "Te hemos enviado un email de validación. Comprueba tu bandeja de entrada."
  },
  profile_success_selector_phone: {
    id: "profile.success_selector_phone",
    defaultMessage: "Te hemos enviado un SMS para validar este número."
  },
  profile_selector_validated: {
    id: "profile.selector_validated_status",
    defaultMessage: "Validado"
  },
  profile_selector_pending: {
    id: "profile.selector_pending_status",
    defaultMessage: "Pendiente"
  },
  profile_selector_no_validated: {
    id: "profile.selector_no_validated_status",
    defaultMessage: "Sin validar"
  },
  selector_validation_confirm_title: {
    id: "selector_validation.confirm_title",
    defaultMessage: "Confirma tu {selectorType}"
  },
  selector_validation_confirm_description: {
    id: "selector_validation.confirm_description",
    defaultMessage: `Se ha añadido el {selectorType} {selectorValue} a tu cuenta de SeQura,
    pulsando el botón Validar confirmas que el {selectorType} es tuyo y SeQura puede
    usarlo para identificarte junto con el resto de {selectorPluralType} de tu cuenta.`
  },
  selector_validation_confirm_button: {
    id: "selector_validation.confirm_button",
    defaultMessage: `Validar`
  },
  selector_validation_confirmed_title: {
    id: "selector_validation.confirmed_title",
    defaultMessage: "Proceso de validación completado"
  },
  selector_validation_confirmed_description: {
    id: "selector_validation.confirmed_description",
    defaultMessage: `El proceso de validación para tu {selectorType} {selectorValue}
    se ha completado con éxito. A partir de ahora tus compras con este
    {selectorType} serán incluidas en tu cuenta.`
  },
  selector_validation_already_validated_description: {
    id: "selector_validation.already_validated_description",
    defaultMessage: "El {selectorType} {selectorValue} ya ha sido validado."
  },
  selector_validation_expired_title: {
    id: "selector_validation.expired_title",
    defaultMessage: "Proceso de validación expirado"
  },
  selector_validation_expired_description: {
    id: "selector_validation.expired_description",
    defaultMessage: `El proceso de validación del {selectorType} {selectorValue} ha expirado.`
  },
  selector_validation_back_button: {
    id: "selector_validation.back_button",
    defaultMessage: `Ir a tus pedidos`
  },
  selector_validation_login_button: {
    id: "selector_validation.login_button",
    defaultMessage: `Acceder`
  },
  InvalidSelector: {
    id: "profile.invalid_selector",
    defaultMessage: "El {selectorType} es incorrecto"
  },
  AlreadyAddedSelector: {
    id: "profile.already_added_selector",
    defaultMessage: "Este {selectorType} ya existe"
  },
  AlreadyAddedSelectorNumber: {
    id: "profile.already_added_selector_number",
    defaultMessage: "número"
  },
  AlreadyAddedSelectorEmail: {
    id: "profile.already_added_selector_email",
    defaultMessage: "email"
  },
  AlreadyValidatedSelector: {
    id: "profile.already_validated_selector",
    defaultMessage: "El {selectorType} ya ha sido validado"
  },
  AlreadyBeingValidatedSelector: {
    id: "profile.already_beign_validated_selector",
    defaultMessage: "El {selectorType} está siendo validado"
  },
  payment_cards_auto_payment_title: {
    id: "payment_cards.auto_payment_title",
    defaultMessage: "Pago automático"
  },
  payment_cards_auto_payment_description: {
    id: "payment_cards.auto_payment_description",
    defaultMessage: `Despreocúpate de pagar tus próximas compras a siete días activando el pago automático.
    SeQura siempre te avisarará antes de que se efectúe cualquier cobro.`
  },
  payment_cards_auto_payment_more_info: {
    id: "payment_cards.auto_payment_more_info",
    defaultMessage: "+ Info"
  },
  payment_cards_list_title: {
    id: "payment_cards.list_title",
    defaultMessage: "Tarjetas guardadas"
  },
  payment_cards_list_description: {
    id: "payment_cards.list_description",
    defaultMessage: "Saldrán sugeridas en tus próximas compras."
  },
  payment_cards_add_new_card: {
    id: "payment_cards.add_new_card",
    defaultMessage: "Añadir tarjeta"
  },
  payment_cards_list: {
    id: "payment_cards.list",
    defaultMessage: "Tarjetas guardadas"
  },
  payment_cards_error_on_assign_card: {
    id: "payment_cards.error_on_assign_card",
    defaultMessage:
      "Ha ocurrido un error, por favor inténtalo de nuevo más tarde o cambia de tarjeta"
  },
  auto_payment_without_favorite_card_message: {
    id: "auto_payment.without_favorite_card_message",
    defaultMessage:
      "Selecciona una tarjeta como favorita para poder activar el Paga Después automático"
  },
  auto_payment_description: {
    id: "auto_payment.description",
    defaultMessage:
      "Paga automáticamente tus compras a los 7 días del envío con tu tarjeta favorita."
  },
  auto_payment_title: {
    id: "auto_payment.title",
    defaultMessage: "Más opciones"
  },
  auto_payment_label: {
    id: "auto_payment.label",
    defaultMessage: "Paga Después automático"
  },
  next_purchases_modal_title: {
    id: "next_purchases_modal.title",
    defaultMessage: "Haz tus próximas compras aún más cómodas"
  },
  next_purchases_modal_first_paragraph: {
    id: "next_purchases_modal.first_paragraph",
    defaultMessage:
      "Guarda las tarjetas que más uses de forma segura. En tu próxima compra solo tendrás que elegir con cual quieres realizar el pago."
  },
  next_purchases_modal_second_paragraph: {
    id: "next_purchases_modal.second_paragraph",
    defaultMessage: "Si eliges una como favorita será la sugerida por defecto."
  },
  next_purchases_modal_action: {
    id: "next_purchases_modal.action",
    defaultMessage: "Entendido"
  },
  update_card_info_title: {
    id: "update_card_info.title",
    defaultMessage: "Si quieres cambiar la tarjeta de un pago a plazos"
  },
  update_card_info_sub_title: {
    id: "update_card_info.sub_title",
    defaultMessage: "encontrarás la opción en la página de la compra."
  },
  update_card_info_action: {
    id: "update_card_info.action",
    defaultMessage: "Ver mis compras"
  },
  update_cards_flow_title: {
    id: "update_cards_flow_title",
    defaultMessage: "Tarjeta actualizada"
  },
  update_cards_flow_sub_title: {
    id: "update_cards_flow_sub_title",
    defaultMessage: "¿Quieres usar esta tarjeta para las otras compras?"
  },
  update_cards_flow_error_messages_title: {
    id: "update_cards_flow_error_messages_title",
    defaultMessage:
      "Tienes {orders, plural, =1 {otra compra} other {otras # compras}} en curso."
  },
  update_cards_flow_error_messages_sub_title: {
    id: "update_cards_flow_error_messages_sub_title",
    defaultMessage: "Paga antes los importes atrasados para cambiar la tarjeta."
  },
  update_cards_flow_error_messages_action: {
    id: "update_cards_flow_error_messages_action",
    defaultMessage: "Ver pagos pendientes"
  },
  update_cards_flow_update_button: {
    id: "update_cards_flow_update_button",
    defaultMessage: "Actualizar"
  },
  update_cards_flow_updated_button: {
    id: "update_cards_flow_updated_button",
    defaultMessage: "Actualizada"
  },
  update_cards_flow_info_date: {
    id: "update_cards_flow_info_date",
    defaultMessage: "Fecha de compra:"
  },
  update_cards_flow_info_total: {
    id: "update_cards_flow_info_total",
    defaultMessage: "Total:"
  },
  update_cards_flow_info_articles: {
    id: "update_cards_flow_info_articles",
    defaultMessage: "Artículos:"
  },
  update_cards_assing_card_error: {
    id: "update_cards_assing_card_error",
    defaultMessage:
      "Algo ha fallado y no se ha podido cambiar la tarjeta. Prueba de nuevo o llámanos si es urgente"
  },
  login_enter: {
    id: "login.enter",
    defaultMessage: "Entrar"
  },
  login_send_access: {
    id: "login_send_access",
    defaultMessage: "Enviar acceso"
  },
  login_welcome_title: {
    id: "login.welcome_title",
    defaultMessage: "Entra en tu cuenta"
  },
  login_account_verification: {
    id: "login.account_verification",
    defaultMessage: "Verificación de cuenta"
  },
  login_form_nin: {
    id: "login.form_nin",
    defaultMessage: "DNI/NIE"
  },
  login_form_email_or_phone: {
    id: "login.form_email_or_phone",
    defaultMessage: "Email o Teléfono"
  },
  login_form_error: {
    id: "login.form_error",
    defaultMessage: "Introduce tus datos."
  },
  login_form_error_no_dni: {
    id: "login_form_error_no_dni",
    defaultMessage: "Introduce tu DNI/NIE."
  },
  login_form_error_no_email_or_phone: {
    id: "login_form_error_no_email_or_phone",
    defaultMessage: "Introduce tu email o teléfono."
  },
  login_form_submit: {
    id: "login.form_submit",
    defaultMessage: "Entrar"
  },
  login_form_remember_sesion: {
    id: "login.form_remember_sesion",
    defaultMessage: "Recordar sesión"
  },
  login_by_email_description: {
    id: "login.by_email_description",
    defaultMessage: "Accede a tu cuenta desde el email que te hemos enviado"
  },
  login_by_email_footer: {
    id: "login.by_email_title",
    defaultMessage: "Si no has recibido el email puedes "
  },
  login_by_email_link: {
    id: "login.by_email_link",
    defaultMessage: "entrar con tu móvil"
  },
  login_by_phone_description: {
    id: "login.by_phone_description",
    defaultMessage: "Introduce el código del SMS"
  },
  login_by_phone_footer: {
    id: "login.by_phone_title",
    defaultMessage: "Si no has recibido el SMS, puedes"
  },
  login_by_phone_link: {
    id: "login.by_phone_link",
    defaultMessage: "entrar con tu email"
  },
  login_by_phone_form_button: {
    id: "login.by_phone_form_button",
    defaultMessage: "Entrar"
  },
  login_by_phone_form_error: {
    id: "login.by_phone_form_error",
    defaultMessage: "Código incorrecto"
  },
  login_error_invalid_arguments: {
    id: "login_error_invalid_arguments",
    defaultMessage:
      "mmm... Parece que algo es incorrecto, comprueba de nuevo tus datos."
  },
  login_error_locked_user: {
    id: "login_error_locked_user",
    defaultMessage:
      "Ups! Por seguridad se ha bloqueado la cuenta, vuelve a intentarlo en 2 horas."
  },
  login_error_generic: {
    id: "login_error_generic",
    defaultMessage: "Error con el servidor. Vuelve a intentarlo."
  },
  sign_up_landing_page_title: {
    id: "sign_up_landing_page_title",
    defaultMessage:
      "{invitedBy} te ha regalado <strong>{amount}*</strong> para tu primera compra con seQura"
  },
  sign_up_landing_paragraph: {
    id: "sign_up_landing_paragraph",
    defaultMessage: "* Para una compra de 50 € o más."
  },
  sign_up_landing_trustpilot_subline: {
    id: "sign_up_landing_trustpilot_subline",
    defaultMessage: "1.5 millones de compradores en España"
  },
  sign_up_landing_terms_terms: {
    id: "sign_up_landing_terms_terms",
    defaultMessage: "Términos y condiciones"
  },
  sign_up_landing_button_action: {
    id: "sign_up_landing_button_action",
    defaultMessage: "Quiero el descuento"
  },
  sign_up_landing_footer_text: {
    id: "sign_up_landing_footer_text",
    defaultMessage:
      "Somos una fintech española fundada en 2013. Ofrecemos métodos de pago flexibles y seguros en más de 4.000 tiendas y servicios. Permitimos hacer compras sin tener que pagar la totalidad del importe en el momento y sin compartir datos bancarios con las tiendas."
  },
  sign_up_landing_footer_link_faq: {
    id: "sign_up_landing_footer_link_faq",
    defaultMessage: "Preguntas frecuentes"
  },
  sign_up_landing_footer_link_legal: {
    id: "sign_up_landing_footer_link_legal",
    defaultMessage: "Información legal"
  },
  sign_up_landing_footer_copyright: {
    id: "sign_up_landing_footer_copyright",
    defaultMessage: "© Copyright - Sequra Worldwide S.A."
  },
  sign_up_action: {
    id: "sign_up_action",
    defaultMessage: " Crear cuenta"
  },
  sign_up_step_form_name_title: {
    id: "sign_up_step_form_name_title",
    defaultMessage: "¡Hola!"
  },
  sign_up_step_form_name_subtitle: {
    id: "sign_up_step_form_name_subtitle",
    defaultMessage: "¿Cómo te gusta que te llamen?"
  },
  sign_up_step_form_name_label_input: {
    id: "sign_up_step_form_name_label_input",
    defaultMessage: "Nombre"
  },
  sign_up_step_form_identification_subtitle: {
    id: "sign_up_step_form_identification_subtitle",
    defaultMessage: "Vamos a crear tu cuenta"
  },
  sign_up_step_form_identification_claim: {
    id: "sign_up_step_form_identification_claim",
    defaultMessage:
      "Para identificarte ¿qué es más fácil de recordar que tu DNI/NIE? 🤓"
  },
  sign_up_step_form_identification_label_input: {
    id: "sign_up_step_form_identification_label_input",
    defaultMessage: "DNI o NIE"
  },
  sign_up_step_form_identification_error: {
    id: "sign_up_step_form_identification_error",
    defaultMessage: "Introduce un DNI/NIE  válido"
  },
  sign_up_step_form_email_subtitle: {
    id: "sign_up_step_form_email_subtitle",
    defaultMessage: "¿Cuál es tu email?"
  },
  sign_up_step_form_email_claim: {
    id: "sign_up_step_form_email_claim",
    defaultMessage:
      "Para comunicaciones necesarias de tu cuenta. Libre de spam 💃"
  },
  sign_up_step_form_email_label_input: {
    id: "sign_up_step_form_email_label_input",
    defaultMessage: "Email"
  },
  sign_up_step_form_email_error: {
    id: "sign_up_step_form_email_error",
    defaultMessage: "Introduce un email válido"
  },
  sign_up_step_form_phone_subtitle: {
    id: "sign_up_step_form_phone_subtitle",
    defaultMessage: "¿Y tu número de móvil?"
  },
  sign_up_step_form_phone_claim: {
    id: "sign_up_step_form_phone_claim",
    defaultMessage:
      "Te enviaremos ahora la contraseña de un solo uso. No tendrás que recordar contraseñas :)"
  },
  sign_up_step_form_phone_label_input: {
    id: "sign_up_step_form_phone_label_input",
    defaultMessage: "Número móvil"
  },
  sign_up_step_form_phone_privacy_policy: {
    id: "sign_up_step_form_phone_privacy_policy",
    defaultMessage: "Entiendo y acepto la {link}."
  },
  sign_up_step_form_phone_error: {
    id: "sign_up_step_form_phone_error",
    defaultMessage: "Introduce un teléfono válido"
  },
  sign_up_step_form_otp_subtitle: {
    id: "sign_up_step_form_otp_subtitle",
    defaultMessage: "El código es..."
  },
  sign_up_step_form_otp_claim: {
    id: "sign_up_step_form_otp_claim",
    defaultMessage: "Te hemos enviado un SMS al "
  },
  sign_up_step_form_otp_action: {
    id: "sign_up_step_form_otp_action",
    defaultMessage: "Volver a enviar el SMS"
  },
  sign_up_step_form_otp_error: {
    id: "sign_up_step_form_otp_error",
    defaultMessage: "Parece que el código no es correcto"
  },
  sign_up_step_form_account_existed_title: {
    id: "sign_up_step_form_account_existed_title",
    defaultMessage: "Vaya… Parece que ya tienes cuenta en SeQura"
  },
  sign_up_step_form_account_existed_claim: {
    id: "sign_up_step_form_account_existed_claim",
    defaultMessage:
      "Esta promoción es solo para nuevos registros.{br}{br} Entra en tu cuenta y refresca la memoria consultando las compras que ya has hecho con SeQura."
  },
  sign_up_step_form_account_existed_action: {
    id: "sign_up_step_form_account_existed_action",
    defaultMessage: "Entrar en mi cuenta"
  },
  sign_up_successful_modal_title: {
    id: "sign_up_successful_modal_title",
    defaultMessage:
      "¡Enhorabuena!{br} Tienes <strong>{amount}</strong> de descuento para tu primera compra con SeQura"
  },
  sign_up_successful_modal_first_paragraph: {
    id: "sign_up_successful_modal_first_paragraph",
    defaultMessage: "Te explicamos cómo usarlo en un plis."
  },
  merchants_without_cost_modal_title: {
    id: "merchants_without_cost_modal_title",
    defaultMessage:
      "En estas tiendas, paga en 3 plazos o al recibir tu compra sin coste adicional"
  },
  merchants_without_cost_modal_sector_fashion: {
    id: "merchants_without_cost_modal_sector_fashion",
    defaultMessage: "Moda"
  },
  merchants_without_cost_modal_sector_sports: {
    id: "merchants_without_cost_modal_sector_sports",
    defaultMessage: "Deporte"
  },
  merchants_without_cost_modal_sector_home: {
    id: "merchants_without_cost_modal_sector_home",
    defaultMessage: "Hogar"
  },
  merchants_without_cost_modal_sector_baby_kids: {
    id: "merchants_without_cost_modal_sector_baby_kids",
    defaultMessage: "Bebés y niños"
  },
  merchants_without_cost_modal_sector_vehicles: {
    id: "merchants_without_cost_modal_sector_vehicles",
    defaultMessage: "Vehículos"
  },
  merchants_without_cost_modal_sector_music_sound: {
    id: "merchants_without_cost_modal_sector_music_sound",
    defaultMessage: "Música y sonido"
  },
  merchants_without_cost_modal_sector_electronics: {
    id: "merchants_without_cost_modal_sector_electronics",
    defaultMessage: "Electrónica"
  },
  merchants_without_cost_modal_sector_hobby: {
    id: "merchants_without_cost_modal_sector_hobby",
    defaultMessage: "Hobbies"
  },
  merchants_without_cost_modal_sector_health_beauty: {
    id: "merchants_without_cost_modal_sector_health_beauty",
    defaultMessage: "Salud y belleza"
  },
  merchants_without_cost_modal_sector_pets: {
    id: "merchants_without_cost_modal_sector_pets",
    defaultMessage: "Mascotas"
  },
  merchants_without_cost_modal_sector_optics: {
    id: "merchants_without_cost_modal_sector_optics",
    defaultMessage: "Ópticas"
  },
  merchants_without_cost_modal_sector_handiwork: {
    id: "merchants_without_cost_modal_sector_handiwork",
    defaultMessage: "Manualidades"
  },
  merchants_without_cost_modal_sector_bazaar: {
    id: "merchants_without_cost_modal_sector_bazaar",
    defaultMessage: "Bazar"
  },
  merchants_without_cost_modal_sector_food_drinks: {
    id: "merchants_without_cost_modal_sector_food_drinks",
    defaultMessage: "Alimentación"
  },
  merchants_without_cost_modal_sector_jewelry: {
    id: "merchants_without_cost_modal_sector_jewelry",
    defaultMessage: "Joyas"
  },
  merchants_without_cost_modal_footer_title: {
    id: "merchants_without_cost_modal_footer_title",
    defaultMessage: "Si has llegado tan abajo es que estás buscando más"
  },
  merchants_without_cost_modal_footer_subtitle: {
    id: "merchants_without_cost_modal_footer_subtitle",
    defaultMessage: "Tenemos todas las tiendas y sectores en la web"
  },
  merchants_without_cost_modal_footer_action: {
    id: "merchants_without_cost_modal_footer_action",
    defaultMessage: "Ver todas las tiendas"
  },
  on_boarding_first_step_title: {
    id: "on_boarding_first_step_title",
    defaultMessage: "Haz tu compra de {amount} o más"
  },
  on_boarding_first_step_paragraph: {
    id: "on_boarding_first_step_paragraph",
    defaultMessage:
      "Cómprate lo que quieras en cualquiera de las tiendas con SeQura."
  },
  on_boarding_second_step_title: {
    id: "on_boarding_second_step_title",
    defaultMessage: "Para pagar elige SeQura"
  },
  on_boarding_second_step_paragraph: {
    id: "on_boarding_second_step_paragraph",
    defaultMessage:
      "Encontrarás 1 o varios de los métodos de pago de SeQura según la tienda."
  },
  on_boarding_third_step_title: {
    id: "on_boarding_third_step_title",
    defaultMessage: "Confirma tus datos"
  },
  on_boarding_third_step_paragraph: {
    id: "on_boarding_third_step_paragraph",
    defaultMessage: "Valida tus datos personales para usar el descuento."
  },
  on_boarding_fourth_step_title: {
    id: "on_boarding_fourth_step_title",
    defaultMessage: "¡Tachán! Usa el descuento"
  },
  on_boarding_fourth_step_paragraph: {
    id: "on_boarding_fourth_step_paragraph",
    defaultMessage:
      "Aparecerá la opción siempre que tu compra sea de 50 € o más."
  },
  on_boarding_fifth_step_title: {
    id: "on_boarding_fifth_step_title",
    defaultMessage: "¡Listo! ¿Ya sabes qué te vas a comprar?"
  },
  orders_more_button: {
    id: "orders.more_button",
    defaultMessage: "Mostrar más"
  },
  orders_no_more_orders_text: {
    id: "orders_no_more_orders_text",
    defaultMessage: "Eso es todo hasta ahora"
  },
  order_ticket_confirmed_at: {
    id: "order_ticket.confirmed_at",
    defaultMessage: "Fecha:"
  },
  order_ticket_reference: {
    id: "order_ticket.reference",
    defaultMessage: "Referencia:"
  },
  order_ticket_amount: {
    id: "order_ticket.amount",
    defaultMessage: "Importe:"
  },
  order_title_cancelled: {
    id: "order_title.cancelled",
    defaultMessage: "Pedido cancelado"
  },
  order_title_unpaid: {
    id: "order_title.unpaid",
    defaultMessage: "Tienes una deuda pendiente de {amount}"
  },
  order_title_paid: {
    id: "order_title.paid",
    defaultMessage: "Pagado el {date}"
  },
  order_title_card_expired: {
    id: "order_title.card_expired",
    defaultMessage: "Tu tarjeta **** **** **** {lastFour} ha caducado"
  },
  order_title_card_expiring_soon: {
    id: "order_title.card_expiring_soon",
    defaultMessage:
      "Tu tarjeta **** **** **** {lastFour} está a punto de caducar"
  },
  order_title_in_progress_automatic: {
    id: "order_title.in_progress_automatic",
    defaultMessage: "Cobro automático el {date} de {amount}"
  },
  order_title_in_progress_automatic_not_shipped: {
    id: "order_title.in_progress_automatic_not_shipped",
    defaultMessage:
      "Cobro automático {days, plural, one {# día} other {# días}} después del envío"
  },
  order_title_in_progress_manually: {
    id: "order_title.in_progress_manually",
    defaultMessage: "Tienes hasta {date} para pagar {amount}"
  },
  order_title_in_progress_manually_not_shipped: {
    id: "order_title.in_progress_manually_not_shipped",
    defaultMessage:
      "Tienes {days, plural, one {# día} other {# días}} después del envío para pagar"
  },
  order_title_in_progress_part_payment: {
    id: "order_title.in_progress_part_payment",
    defaultMessage: "Tu próximo pago es de {amount} el {date}"
  },
  order_title_in_progress_full_payment: {
    id: "order_title.in_progress_full_payment",
    defaultMessage: "El pago de {amount} se cargará el {date}"
  },
  order_subtitle_part_payment_in_progress: {
    id: "order_title.part_payment_in_progress",
    defaultMessage:
      "Pagarás {amount} en {instalments, plural, one {1 cuota} other {# cuotas}}, terminas en {date}"
  },
  order_status_unpaid: {
    id: "order_status.unpaid",
    defaultMessage: "Tienes pendiente {amount}"
  },
  order_status_paid: {
    id: "order_status.paid",
    defaultMessage: "Pagado"
  },
  order_status_card_expired: {
    id: "order_status.card_expired",
    defaultMessage: "Tu tarjeta ha caducado"
  },
  order_status_card_expiring_soon: {
    id: "order_status.card_expiring_soon",
    defaultMessage: "Tu tarjeta está a punto de caducar"
  },
  order_status_shipped: {
    id: "order_status.shipped",
    defaultMessage: "Enviado"
  },
  order_status_partially_shipped: {
    id: "order_status.partially_shipped",
    defaultMessage: "Parcialmente enviado"
  },
  order_status_unshipped: {
    id: "order_status.unshipped",
    defaultMessage: "Pendiente de envío"
  },
  order_action_conversion: {
    id: "order_action.conversion",
    defaultMessage: "Fraccionar"
  },
  order_action_pay_with_amount: {
    id: "order_action.paid_with_amount",
    defaultMessage: "Pagar {amount}"
  },
  order_action_change_card: {
    id: "order_action.change_card",
    defaultMessage: "Cambiar tarjeta"
  },
  order_action_pay: {
    id: "order_action.pay",
    defaultMessage: "Pagar ahora"
  },
  order_autopayment_toggle: {
    id: "order_autopayment.toggle",
    defaultMessage:
      "Activa el pago automático, usaremos la tarjeta terminada en {lastFour}"
  },
  order_shipment: {
    id: "order.shipment",
    defaultMessage: "Envíos"
  },
  order_shipment_selection: {
    id: "order.shipment_selection",
    defaultMessage: "Elige los envíos que quieres pagar"
  },
  order_shipment_total: {
    id: "order.shipment_total",
    defaultMessage: "Total"
  },
  order_return_link: {
    id: "order.return_link",
    defaultMessage: "Notifícanos alguna devolución"
  },
  order_shipment_count_plural: {
    id: "order.shipment_count_plural",
    defaultMessage: `{totalInvoices, plural,
      one {# envío}
      other {# envíos}
    }`
  },
  order_shipment_articles_count_plural: {
    id: "order.shipment_articles_count_plural",
    defaultMessage: `{quantity, plural,
      one {# artículo}
      other {# artículos}
    }`
  },
  order_payment_header_title: {
    id: "order_payment_header_title",
    defaultMessage: "Pagos"
  },
  order_payment_select_invoices_title: {
    id: "order.payment_select_invoices_title",
    defaultMessage: "¿Qué envíos quieres pagar?"
  },
  order_payment_select_invoices_subtitle: {
    id: "order.payment_select_invoices_subtitle",
    defaultMessage: "Selecciona los envíos que quieras apagar ahora."
  },
  order_payment_summary_title: {
    id: "order.payment_summary_title",
    defaultMessage: "Resumen del pago"
  },
  order_payment_pending_days: {
    id: "order.payment_pending_days",
    defaultMessage: `{quantity, plural,
      =0 {Último día para pagar}
      one {# día para pagar}
      other {# días para pagar}
    }`
  },
  order_payment_overdue: {
    id: "order.payment_overdue",
    defaultMessage: "En deuda"
  },
  order_payment_with_card: {
    id: "order.payment_with_card",
    defaultMessage: "Pago con tarjeta"
  },
  order_payment_with_bank_transfer: {
    id: "order.payment_with_bank_transfer",
    defaultMessage: "O si lo prefieres, también puedes hacer un"
  },

  general_close_label: {
    id: "general.close_label",
    defaultMessage: "Cerrar"
  },
  FULL_PAYMENT: {
    id: "accountEvent.full_payment",
    defaultMessage: "Importe"
  },
  CHARGEBACK: {
    id: "accountEvent.chargeback",
    defaultMessage: "Rechazo de cargo"
  },
  EXTERNAL_PAYMENT: {
    id: "accountEvent.external_payment",
    defaultMessage: "Pago con tarjeta"
  },
  SETUP_FEE: {
    id: "accountEvent.setup_fee",
    defaultMessage: "Coste del crédito"
  },
  LATE_FEE: {
    id: "accountEvent.late_fee",
    defaultMessage: "Recargo por atraso"
  },
  INTERNAL_TRANSFER: {
    id: "accountEvent.internal_transfer",
    defaultMessage: "Transfer interno"
  },
  REFUND: {
    id: "accountEvent.refund",
    defaultMessage: "Reembolso"
  },
  LATE_INTEREST: {
    id: "accountEvent.late_interest",
    defaultMessage: "Recargo por atraso"
  },
  CURRENT_LATE_INTEREST: {
    id: "accountEvent.current_late_interest",
    defaultMessage: "Recargo por atraso"
  },
  REGISTRATION: {
    id: "accountEvent.registration",
    defaultMessage: "Ajuste"
  },
  INSTALMENT: {
    id: "accountEvent.instalment",
    defaultMessage: "Importe"
  },
  INSTALMENT_FEE: {
    id: "accountEvent.instalment_fee",
    defaultMessage: "Coste fijo mensual"
  },
  CHANGE_PAYMENT_DATE_INSTALMENT_FEE: {
    id: "accountEvent.change_payment_date_instalment_fee",
    defaultMessage: "Cambio día de pago"
  },
  PAYMENT: {
    id: "accountEvent.payment",
    defaultMessage: "Pago"
  },
  DOWN_PAYMENT: {
    id: "accountEvent.down_payment",
    defaultMessage: "Importe"
  },
  DOWN_PAYMENT_FEE: {
    id: "accountEvent.down_payment_fee",
    defaultMessage: "Coste fijo mensual"
  },
  EARLY_REDEMPTION: {
    id: "accountEvent.early_redemption",
    defaultMessage: "Amortización anticipada"
  },
  EARLY_REDEMPTION_FEE: {
    id: "accountEvent.early_redemption_fee",
    defaultMessage: "Coste fijo"
  },
  BANK_TRANSFER: {
    id: "accountEvent.bank_transfer",
    defaultMessage: "por transferencia"
  },
  CARD_PAYMENT: {
    id: "accountEvent.card_payment",
    defaultMessage: "con tarjeta"
  },
  MANUAL_TPV: {
    id: "accountEvent.manual_tpv",
    defaultMessage: "con tarjeta"
  },
  CART_ADJUSTMENT: {
    id: "accountEvent.cart_adjustment",
    defaultMessage: "Ajuste"
  },
  RENTING_MONTHLY_RENT: {
    id: "accountEvent.renting_monthly_rent",
    defaultMessage: "Importe"
  },
  RENTING_MONTHLY_INSURANCE: {
    id: "accountEvent.renting_monthly_insurance",
    defaultMessage: "Seguro"
  },
  REFERRAL_VOUCHER: {
    id: "accountEvent.referral_voucher",
    defaultMessage: "Pago con descuento SeQura ✨"
  },
  GENERAL_VOUCHER: {
    id: "accountEvent.general_voucher",
    defaultMessage: "Pago con descuento SeQura ✨"
  },
  general_button_close: {
    id: "general.button_close",
    defaultMessage: "Cerrar"
  },
  order_payment_finished_title: {
    id: "order.payment_finished_title",
    defaultMessage: "¡Pago finalizado!"
  },
  order_payment_success_title: {
    id: "order.payment_success_title",
    defaultMessage: "Pago completado"
  },
  order_payment_current_order_value_equal_to_zero_message: {
    id: "order.payment_current_order_value_equal_to_zero_message",
    defaultMessage: "Gracias por confiar en SeQura para realizar tus compras."
  },
  order_payment_current_order_value_higher_than_zero_message: {
    id: "order.payment_current_order_value_higher_than_zero_message",
    defaultMessage: "Queda pendiente {amount} en esta compra."
  },
  order_payment_current_order_value_higher_than_zero_on_pp_message: {
    id: "order.payment_current_order_value_higher_than_zero_on_pp_message",
    defaultMessage:
      "Te quedan {instalmentsAmount} en {totalInstalments, plural, one {1 cuota} other {# cuotas}} para finalizar el plan de pago."
  },
  order_payment_current_order_value_higher_than_zero_on_pp_assing_card_message: {
    id:
      "order.payment_current_order_value_higher_than_zero_on_pp_assing_card_message",
    defaultMessage: "Se ha asociado a esta compra."
  },
  order_payment_current_order_value_equal_to_zero_on_pp_message: {
    id: "order.payment_current_order_value_equal_to_zero_on_pp_message",
    defaultMessage:
      "¡Queda finalizado el plan de pago!\nGracias por confiar en SeQura"
  },
  order_payment_failed_title: {
    id: "order.payment_failed_title",
    defaultMessage: "¡Ups! Algo ha fallado"
  },
  order_payment_failed_message: {
    id: "order.payment_failed_message",
    defaultMessage: "Vuelve a intentarlo más tarde"
  },
  order_payment_invoices_pending_message: {
    id: "order.payment_invoices_pending_message",
    defaultMessage: "Queda pendiente {amount} en esta compra."
  },
  order_payment_instalments_pending_message: {
    id: "order.payment_instalments_pending_message",
    defaultMessage:
      "Te quedan {amount} en {instalments} cuotas para finalizar el plan de pago."
  },
  order_conversion_modal_title: {
    id: "order.conversion_modal_title",
    defaultMessage: "¿En cuántos meses quieres pagar?"
  },
  order_conversion_modal_description: {
    id: "order.conversion_modal_description",
    defaultMessage: "Elige el plan de pago que mejor se adapte a ti"
  },
  order_conversion_info_order_amount_title: {
    id: "order.conversion_info_order_amount_title",
    defaultMessage: "Tu compra"
  },
  order_conversion_info_instalment_amount_title: {
    id: "order.conversion_info_instalment_amount_title",
    defaultMessage: "Coste ({amount} por cuota)"
  },
  order_conversion_info_link_details: {
    id: "order.conversion_info_link_details",
    defaultMessage: "Información detallada"
  },
  order_conversion_instalments_counts: {
    id: "order.conversion_instalments_counts",
    defaultMessage: "{amount} cuotas"
  },
  order_conversion_per_month_label: {
    id: "order.conversion_per_month_label",
    defaultMessage: "al mes"
  },
  order_conversion_downpayment_label: {
    id: "order.conversion_downpayment_label",
    defaultMessage: "Primera cuota"
  },
  order_return_request_select_invoices_title: {
    id: "order.return_request_select_invoices_title",
    defaultMessage: "¿De qué envío has hecho un cambio?"
  },
  order_return_request_select_invoices_subtitle: {
    id: "order.return_request_select_invoices_subtitle",
    defaultMessage:
      "Selecciona los envíos de los que has hecho un cambio o devolución"
  },
  order_return_request_deliver_message: {
    id: "order.return_request_deliver_message",
    defaultMessage:
      "Cuando la tienda nos confirme la devolución, recibirás un email con los detalles actualizados."
  },
  order_return_request_return_message: {
    id: "order.return_request_return_message",
    defaultMessage: "Recuerda gestionar antes la devolución con la tienda"
  },
  order_return_request_tag_price_message: {
    id: "order.return_request_tag_price_message",
    defaultMessage:
      "Si la tienda tiene gastos de devolución podrían sumarse al importe pendiente."
  },
  order_return_request_invoices_summary_title: {
    id: "order.return_request_invoices_summary_title",
    defaultMessage: "Resúmen del cambio o devolución"
  },
  order_return_request_invoices_summary_subtitle: {
    id: "order.return_request_invoices_summary_subtitle",
    defaultMessage:
      "Estos son los envíos los cuales has cambiado o devuelto algún artículo"
  },
  order_return_request_navigation_inform: {
    id: "order.return_request_navigation_inform",
    defaultMessage: "Informar devolución"
  },
  order_return_request_notification_success: {
    id: "order.return_request_notification_success",
    defaultMessage: "¡Devolución confirmada!"
  },
  order_return_request_notification_error: {
    id: "order.return_request_notification_error",
    defaultMessage: "Ha habido un error con la confirmación de devolución"
  },
  order_return_request_navigation_confirm: {
    id: "order.return_request_navigation_confirm",
    defaultMessage: "Confirmar devolución"
  },
  article: {
    id: "article",
    defaultMessage: "Artículo"
  },
  articles: {
    id: "articles",
    defaultMessage: "Artículos"
  },
  shipped: {
    id: "shipped",
    defaultMessage: "Enviado"
  },
  shipped_plural: {
    id: "shipped_plural",
    defaultMessage: "Enviados"
  },
  unshipped: {
    id: "unshipped",
    defaultMessage: "Pendiente de envío"
  },
  pending: {
    id: "pending",
    defaultMessage: "Pendiente"
  },
  total: {
    id: "total",
    defaultMessage: "Total"
  },
  purchase_summary: {
    id: "purchase_summary",
    defaultMessage: "Resumen de compra"
  },
  order_page_unshipped: {
    id: "order_page_unshipped",
    defaultMessage:
      "Aún no hay ningún pago pendiente. {br} Te avisaremos por email :)"
  },
  order_page_discount_applied: {
    id: "order_page_discount_applied",
    defaultMessage: "Descuento aplicado en esta compra: {amount}"
  },
  item_quantity_and_price_with_tax: {
    id: "item_quantity_and_price_with_tax",
    defaultMessage: "{quantity}u x ({price_with_tax})"
  },
  item_quantity: {
    id: "item_quantity",
    defaultMessage: "{quantity}u"
  },
  items_quantity: {
    id: "items_quantity",
    defaultMessage: "{quantity, plural, one {# artículo} other {# artículos}}"
  },
  name: {
    id: "name",
    defaultMessage: "Nombre"
  },
  price: {
    id: "price",
    defaultMessage: "Precio"
  },
  quantity: {
    id: "quantity",
    defaultMessage: "Cantidad"
  },
  number_of_items: {
    id: "number_of_items",
    defaultMessage: "Unidades"
  },
  status: {
    id: "status",
    defaultMessage: "Estado"
  },
  pendingPayment: {
    id: "pendingPayment",
    defaultMessage: "Pendiente de pago"
  },
  paid: {
    id: "paid",
    defaultMessage: "Pagado"
  },
  overdue: {
    id: "overdue",
    defaultMessage: "En deuda"
  },
  delayed: {
    id: "delayed",
    defaultMessage: "Atrasado"
  },
  scheduled: {
    id: "scheduled",
    defaultMessage: "Programado"
  },
  reference: {
    id: "reference",
    defaultMessage: "Concepto"
  },
  shipment: {
    id: "shipment",
    defaultMessage: "Envío"
  },
  shipments: {
    id: "shipments",
    defaultMessage: "Envíos"
  },
  past_instalments: {
    id: "past_instalments",
    defaultMessage: "Historial de pagos"
  },
  future_instalments: {
    id: "future_instalments",
    defaultMessage: "Próximos pagos"
  },
  bank_transfer: {
    id: "bank_transfer",
    defaultMessage: "Transferencia bancaria"
  },
  credit_card_mask: {
    id: "credit_card_mask",
    defaultMessage: "···· ···· ···· {lastFour}"
  },
  order_reference: {
    id: "order_reference",
    defaultMessage: "ref. {reference}"
  },
  order_paid: {
    id: "order_paid",
    defaultMessage: "Pagada"
  },
  order_cancelled: {
    id: "order_cancelled",
    defaultMessage: "Cancelada"
  },
  order_overdue: {
    id: "order_overdue",
    defaultMessage: "Retraso en el pago"
  },
  order_paid_on: {
    id: "order_paid_on",
    defaultMessage: "Finalizada el: {date}"
  },
  order_next_intalment_payment: {
    id: "order_next_intalment_payment",
    defaultMessage: "Próxima cuota el {date}: {amount}"
  },
  order_unique_instalment_included: {
    id: "order_unique_instalment_included",
    defaultMessage: "Coste fijo por cuota incluidos"
  },
  amount: {
    id: "amount",
    defaultMessage: "Importe"
  },
  date: {
    id: "date",
    defaultMessage: "Fecha"
  },
  invoice_unshipped_days_for_payment: {
    id: "invoice_unshipped_days_for_payment",
    defaultMessage:
      "Cuando tu pedido salga de la tienda, tendrás {days} días para pagar."
  },
  partially_paid_amount_from: {
    id: "partially_paid_amount_from",
    defaultMessage: "Pagado {paid} de {unpaid}"
  },
  order_shipped: {
    id: "order_shipped",
    defaultMessage: "Compra en camino"
  },
  instalment: {
    id: "instalment",
    defaultMessage: "Cuota"
  },
  instalments: {
    id: "instalments",
    defaultMessage: "Pagos"
  },
  total_paid: {
    id: "total_paid",
    defaultMessage: "Total pagado"
  },
  total_unpaid: {
    id: "total_unpaid",
    defaultMessage: "Total restante"
  },
  total_overdue: {
    id: "total_overdue",
    defaultMessage: "Total pendiente"
  },
  day: {
    id: "day",
    defaultMessage: "Día"
  },
  days: {
    id: "days",
    defaultMessage: "Días"
  },
  non_payment: {
    id: "non_payment",
    defaultMessage: "Impago"
  },
  for_payment: {
    id: "for_payment",
    defaultMessage: "Para pagar"
  },
  conversion: {
    id: "conversion",
    defaultMessage: "Fraccionar"
  },
  pay_debt: {
    id: "pay_debt",
    defaultMessage: "Pagar ahora"
  },
  go_to_payment: {
    id: "go_to_payment",
    defaultMessage: "Pagar ahora"
  },
  help_message: {
    id: "help_message",
    defaultMessage:
      "Contacta con nuestro equipo para analizar tu caso y ayudarte en todo lo que podamos."
  },
  we_are_here_to_help_you: {
    id: "we_are_here_to_help_you",
    defaultMessage: "Estamos aquí para ayudarte"
  },
  help_working_day: {
    id: "help_working_day",
    defaultMessage: "L a V: 9:00 a 14:00/15:00 a 18:00"
  },
  help_phone_number: {
    id: "help_phone_number",
    defaultMessage: "931 760 008"
  },
  auto_payment: {
    id: "auto_payment",
    defaultMessage: "Cargo automático"
  },
  payment_status: {
    id: "payment_status",
    defaultMessage: "Estado del pago"
  },
  payment_history: {
    id: "payment_history",
    defaultMessage: "Pagos"
  },
  change_card: {
    id: "change_card",
    defaultMessage: "Cambiar tarjeta"
  },
  expiring_soon: {
    id: "expiring_soon",
    defaultMessage: "A punto de caducar"
  },
  expired: {
    id: "expired",
    defaultMessage: "Caducada"
  },
  next_payment: {
    id: "next_payment",
    defaultMessage: "Próximo pago"
  },
  next_instalment: {
    id: "next_instalment",
    defaultMessage: "Próxima cuota"
  },
  payment_by_transfer: {
    id: "payment_by_transfer",
    defaultMessage: "Pago por transferencia"
  },
  payment_by_transfer_description: {
    id: "payment_by_transfer_description",
    defaultMessage:
      "Una vez realizada la transferencia, te enviaremos un email confirmando el pago en 1-2 días laborables."
  },
  copy: {
    id: "copy",
    defaultMessage: "Copiar"
  },
  copied: {
    id: "copied",
    defaultMessage: "Copiado"
  },
  back: {
    id: "back",
    defaultMessage: "Atrás"
  },
  cancel: {
    id: "cancel",
    defaultMessage: "Cancelar"
  },
  payment_summary: {
    id: "payment_summary",
    defaultMessage: "Resumen del pago"
  },
  more_options: {
    id: "more_options",
    defaultMessage: "Más opciones"
  },
  shopping_basket: {
    id: "shopping_basket",
    defaultMessage: "Carrito"
  },
  actives: {
    id: "actives",
    defaultMessage: "Activas"
  },
  finished: {
    id: "finished",
    defaultMessage: "Finalizadas"
  },
  empty_actives_title: {
    id: "empty_actives_title",
    defaultMessage: "No tienes ninguna compra activa a la vista"
  },
  empty_actives_subtitle_one: {
    id: "empty_actives_subtitle_one",
    defaultMessage: "¿No encuentras lo que buscas?"
  },
  empty_actives_subtitle_two: {
    id: "empty_actives_subtitle_two",
    defaultMessage: "Prueba en la nueva sección de compras"
  },
  empty_finished_title: {
    id: "empty_finished_title",
    defaultMessage: "Todavía nada por aquí"
  },
  empty_finished_subtitle_one: {
    id: "empty_finished_subtitle_one",
    defaultMessage: "Aquí quedarán guardadas todas tus compras pagadas."
  },
  request_login_modal_title: {
    id: "request_login_modal_title",
    defaultMessage:
      "Por seguridad, esta sesión solo te permite acceder a esta compra."
  },
  request_login_modal_subtitle: {
    id: "request_login_modal_subtitle",
    defaultMessage:
      "Entra con tu DNI/NIE para ver el resto de los detalles de tu cuenta."
  },
  session_expired_modal_title: {
    id: "session_expired_modal_title",
    defaultMessage: "La sesión ha caducado"
  },
  session_expired_modal_subtitle: {
    id: "session_expired_modal_subtitle",
    defaultMessage: "Por favor, vuelve a entrar en tu cuenta"
  },
  set_default_shopper_card_reference: {
    id: "set_default_shopper_card_reference",
    defaultMessage:
      "Vas a usar la tarjeta **** **** **** {lastFour} como favorita para el Paga Después automático. ¿Quieres continuar?"
  },
  remove_shopper_card_reference: {
    id: "remove_shopper_card_reference",
    defaultMessage:
      "Vas a eliminar la tarjeta **** **** **** {lastFour} de tu cuenta de usuario. ¿Quieres continuar?"
  },
  set_default_order_card_reference: {
    id: "set_default_order_card_reference",
    defaultMessage:
      "Los cargos futuros se realizarán con la tarjeta **** **** **** {lastFour}. ¿Quieres continuar?"
  },
  remove_order_card_reference: {
    id: "remove_order_card_reference",
    defaultMessage:
      "Vas a eliminar la tarjeta **** **** **** {lastFour}. ¿Quieres continuar?"
  },
  change: {
    id: "change",
    defaultMessage: "Cambiar"
  },
  my_cards: {
    id: "my_cards",
    defaultMessage: "Mis tarjetas"
  },
  expired_card_long_message: {
    id: "expired_card_long_message",
    defaultMessage:
      "Esta tarjeta ha caducado. Selecciona otra tarjeta o añade una nueva para evitar cargos de impago."
  },
  expiring_soon_card_long_message: {
    id: "expiring_soon_card_long_message",
    defaultMessage:
      "Esta tarjeta está a punto de caducar. Cambia de tarjeta o añade una nueva."
  },
  expiring_soon_card_long_message_two: {
    id: "expiring_soon_card_long_message_two",
    defaultMessage:
      "La fecha de caducidad de esta tarjeta es demasiado próxima para seguir usándola en esta compra"
  },
  blocked_card_long_message: {
    id: "blocked_card_long_message",
    defaultMessage:
      "Debido a la nueva normativa, es necesario verificar tu tarjeta. Vuelve a añadirla y sigue los pasos que indique tu banco."
  },
  new_card: {
    id: "new_card",
    defaultMessage: "Añadir tarjeta"
  },
  confirm: {
    id: "confirm",
    defaultMessage: "Confirmar"
  },
  ups_something_went_wrong: {
    id: "ups_something_went_wrong",
    defaultMessage: "Ups! Algo ha ido mal... Vuelve a intentarlo"
  },
  updated_your_account_card: {
    id: "updated_your_account_card",
    defaultMessage: "Actualizada la tarjeta de tu cuenta"
  },
  updated_your_order_card: {
    id: "updated_your_order_card",
    defaultMessage: "Actualizada la tarjeta de tu compra en {merchantName}"
  },
  payment_card_mask_number: {
    id: "payment_card_mask_number",
    defaultMessage: "···· {lastFour}"
  },
  verification_when_new_card_message_line: {
    id: "verification_when_new_card_message_line",
    defaultMessage:
      "Cobramos 1 € para validar la tarjeta. {br} ¡No te preocupes, te lo devolvemos al instante!"
  },
  quantity_instalments: {
    id: "quantity_instalments",
    defaultMessage: "{quantity, plural, one {# cuota} other {# cuotas}}"
  },
  conversion_plan_info: {
    id: "conversion_plan_info",
    defaultMessage: "Información sobre el plan de pago"
  },
  choose_your_plan_payment: {
    id: "choose_your_plan_payment",
    defaultMessage: "Elige tu plan de pago"
  },
  credit_info: {
    id: "credit_info",
    defaultMessage: "Información del crédito"
  },
  payment_plan: {
    id: "payment_plan",
    defaultMessage: "Plan de pago"
  },
  choose_a_card: {
    id: "choose_a_card",
    defaultMessage: "Elige una tarjeta"
  },
  payment_by_transfer_or_account_income: {
    id: "payment_by_transfer_or_account_income",
    defaultMessage: "Pagar por transferencia"
  },
  payment_finished: {
    id: "payment_finished",
    defaultMessage: "¡Tu pago se ha completado con éxito!"
  },
  order_converted_successfully: {
    id: "order_converted_successfully",
    defaultMessage: "¡Tu pago se ha fraccionado con éxito!"
  },
  shipments_with_quantity: {
    id: "shipments_with_quantity",
    defaultMessage: "{quantity, plural, one {# envío} other {# envíos}}"
  },
  dashboard_shops_title: {
    id: "dashboard_shops_title",
    defaultMessage: "Tiendas que te pueden interesar"
  },
  dashboard_shops_news_title: {
    id: "dashboard_shops_news_title",
    defaultMessage: "Novedades de tus tiendas"
  },
  dashboard_shops_button: {
    id: "dashboard_shops_button",
    defaultMessage: "¡Descubre todas nuestras tiendas!"
  },
  dashboard_last_purchase: {
    id: "dashboard_last_purchase",
    defaultMessage: "Tu última compra"
  },
  dashboard_see_order: {
    id: "dashboard_see_order",
    defaultMessage: "Ver compra"
  },
  dashboard_without_next_payments: {
    id: "dashboard_without_next_payments",
    defaultMessage: "Sin pagos a la vista"
  },
  dashboard_recent_shops: {
    id: "dashboard_recent_shops",
    defaultMessage: "Tiendas recientes"
  },
  dashboard_message_invoice_not_sent: {
    id: "dashboard_message_invoice_not_sent",
    defaultMessage: "Aún no hay fecha de pago"
  },
  dashboard_message_invoice_shipped: {
    id: "dashboard_message_invoice_shipped",
    defaultMessage: "¡Tu compra ya está en camino!"
  },
  dashboard_message_invoice_shipped_payment_soon: {
    id: "dashboard_message_invoice_shipped_payment_soon",
    defaultMessage: "¡No te olvides! Se acerca tu día de pago"
  },
  dashboard_message_you_are_okay: {
    id: "dashboard_message_you_are_okay",
    defaultMessage: "¡Estás al día en todas tus compras!"
  },
  dashboard_message_empty: {
    id: "dashboard_message_empty",
    defaultMessage: "¿Cuál es tu próximo proyecto?"
  },
  dashboard_message_empty_button_text: {
    id: "dashboard_message_empty_button_text",
    defaultMessage: "Descubrir tiendas"
  },
  dashboard_message_expiring_card: {
    id: "dashboard_message_expiring_card",
    defaultMessage: "¡Ojo! Tu tarjeta está a punto de caducar"
  },
  dashboard_message_expired_card: {
    id: "dashboard_message_expired_card",
    defaultMessage: "¡Ojo! Tu tarjeta ha caducado"
  },
  dashboard_message_payment_delay: {
    id: "dashboard_message_payment_delay",
    defaultMessage: "¡Importante! El pago va con retraso."
  },
  dashboard_promote_merchants_empty: {
    id: "dashboard_promote_merchants_empty",
    defaultMessage:
      "En este momento no hay novedades. ¡Vuelve a revisar pronto!"
  },
  dashboard_promote_merchants_denied: {
    id: "dashboard_promote_merchants_denied",
    defaultMessage:
      "Tienes {promotions, plural, =1 {# promoción disponible} other {# promociones disponibles}}. ¿Quieres {promotions, plural, =1 {verla} other {verlas}}?"
  },
  dashboard_promote_merchants_denied_action: {
    id: "dashboard_promote_merchants_denied_action",
    defaultMessage:
      "Ver {promotions, plural, =1 {promoción} other {promociones}}"
  },
  dashboard_promote_merchants_pending_title: {
    id: "dashboard_promote_merchants_pending_title",
    defaultMessage: "¡Hola! ¿Quieres ver las promociones de tus tiendas?"
  },
  dashboard_promote_merchants_pending_text: {
    id: "dashboard_promote_merchants_pending_text",
    defaultMessage:
      "Estamos trabajando en esta sección para ofrecerte promociones especiales de tus tiendas para ti. Podrás cambiar de opinión cuando quieras."
  },
  dashboard_promote_merchants_pending_denied: {
    id: "dashboard_promote_merchants_pending_denied",
    defaultMessage: "No me interesa"
  },
  dashboard_promote_merchants_pending_allowed: {
    id: "dashboard_promote_merchants_pending_allowed",
    defaultMessage: "¡Sí quiero verlas!"
  },
  dashboard_promote_merchants_denied_action_link: {
    id: "dashboard_promote_merchants_denied_action_link",
    defaultMessage: "Dejar de ver promociones"
  },
  dashboard_promote_merchants_in_recovery_title: {
    id: "dashboard_promote_merchants_in_recovery_title",
    defaultMessage: "Hola, hemos ocultado las promociones"
  },
  dashboard_promote_merchants_in_recovery_first_text: {
    id: "dashboard_promote_merchants_in_recovery_first_text",
    defaultMessage:
      "Antes de mostrarte nuevas promociones queremos ayudarte a regularizar tus pagos atrasados."
  },
  dashboard_promote_merchants_in_recovery_second_text: {
    id: "dashboard_promote_merchants_in_recovery_second_text",
    defaultMessage:
      "Recuerda que si no te va bien pagar o tienes algún motivo para no hacerlo, estamos a tu disposición en {telephone} para encontrar una solución juntos."
  },
  dashboard_promote_merchants_in_recovery_third_text: {
    id: "dashboard_promote_merchants_in_recovery_third_text",
    defaultMessage:
      "Trabajamos para construir unos servicios financieros más humanos."
  },
  dashboard_referral_program_banner_link: {
    id: "dashboard_referral_program_banner_link",
    defaultMessage: "Invitaciones"
  },
  dashboard_without_orders_merchants_title: {
    id: "dashboard_without_orders_merchants_title",
    defaultMessage: "¿Dónde usar tu descuento?"
  },
  dashboard_without_orders_merchants_title_action: {
    id: "dashboard_without_orders_merchants_title_action",
    defaultMessage: "Ver todos los sectores"
  },
  dashboard_without_orders_voucher_title_banner: {
    id: "dashboard_without_orders_voucher_title_banner",
    defaultMessage: "Descuento acumulado"
  },
  dashboard_without_orders_voucher_subtitle_banner: {
    id: "dashboard_without_orders_voucher_subtitle_banner",
    defaultMessage: "Solo para próximas compras de 50 € o más"
  },
  dashboard_without_orders_merchants_banner_title: {
    id: "dashboard_without_orders_merchants_banner_title",
    defaultMessage: "Disfruta ahora y paga después"
  },
  dashboard_without_orders_merchants_banner_text: {
    id: "dashboard_without_orders_merchants_banner_text",
    defaultMessage: "Tenemos mas de 4.000 tiendas donde elegir"
  },
  dashboard_without_orders_merchants_banner_action: {
    id: "dashboard_without_orders_merchants_banner_action",
    defaultMessage: "Ver todas las tiendas"
  },
  dashboard_without_orders_payment_methods_title: {
    id: "dashboard_without_orders_payment_methods_title",
    defaultMessage: "Conoce nuestros métodos de pago"
  },
  general_page_title_orders: {
    id: "general_page_title_orders",
    defaultMessage: "Tus compras"
  },
  product_pp3: {
    id: "product_pp3",
    defaultMessage: "Paga Fraccionado"
  },
  product_i1: {
    id: "product_i1",
    defaultMessage: "Paga Después"
  },
  product_sp1: {
    id: "product_sp1",
    defaultMessage: "Divide en 3"
  },
  product_pp5: {
    id: "product_pp5",
    defaultMessage: "Paga Aplazado"
  },
  merchant_promotion_card_also_in_stores: {
    id: "merchant_promotion_card_also_in_stores",
    defaultMessage: "También en tienda física"
  },
  merchant_promotion_card_find_your_store: {
    id: "merchant_promotion_card_find_your_store",
    defaultMessage: "Localiza tu tienda"
  },
  merchant_promotion_card_image_not_found: {
    id: "merchant_promotion_card_image_not_found",
    defaultMessage: "Aún no tenemos esta imagen"
  },
  merchant_image_card_physical_stores_label: {
    id: "merchant_image_card_physical_stores_label",
    defaultMessage: "También en tienda física"
  },
  login_form_no_account_title: {
    id: "login_form_no_account_title",
    defaultMessage: "¿No tienes cuenta?"
  },
  login_form_no_account_description: {
    id: "login_form_no_account_description",
    defaultMessage:
      "Haz tu primera compra con SeQura para poder acceder a tu cuenta."
  },
  discover_our_shops: {
    id: "discover_our_shops",
    defaultMessage: "Descubre nuestras tiendas."
  },
  not_match_page_exclamation: {
    id: "not_match_page_exclamation",
    defaultMessage: "Vaya"
  },
  not_match_page_description: {
    id: "not_match_page_description",
    defaultMessage:
      "la pantalla que buscabas no está. Seguramente se ha ido de compras."
  },
  not_match_page_apologize: {
    id: "not_match_page_apologize",
    defaultMessage:
      "Lo sentimos, estas cosas pasan hasta en las mejores familias. Vuelve donde estabas e inténtalo de nuevo."
  },
  not_match_page_back_to_orders: {
    id: "not_match_page_back_to_orders",
    defaultMessage: "Volver a pedidos"
  },
  bank_account_refund_title: {
    id: "bank_account_refund_title",
    defaultMessage: "Proceso de reembolso"
  },
  bank_account_refund_sub_title: {
    id: "bank_account_refund_sub_title",
    defaultMessage: "Importe del reembolso: {amount}"
  },
  order_refund_confirmed_received: {
    id: "order_refund_confirmed_received",
    defaultMessage: "Recibido"
  },
  order_refund_confirmed_notification: {
    id: "order_refund_confirmed_notification",
    defaultMessage:
      "En los próximos días ingresaremos en este número de cuenta el reembolso de {amount}."
  },
  order_refund_confirmed_thanks: {
    id: "order_refund_confirmed_thanks",
    defaultMessage: "Gracias por comprar con nosotros"
  },
  order_refund_confirmed_back_to_order: {
    id: "order_refund_confirmed_back_to_order",
    defaultMessage: "Ver mi pedido"
  },
  order_help_support_title: {
    id: "order_help_support_title",
    defaultMessage: "¿Te podemos ayudar en algo?"
  },
  order_help_support_description: {
    id: "order_help_support_description",
    defaultMessage:
      "No dudes en ponerte en contacto con nuestro equipo para asesorarte en lo que necesites."
  },
  order_help_recovery_title: {
    id: "order_help_recovery_title",
    defaultMessage: "Evita más recargos por impagos"
  },
  order_help_recovery_description: {
    id: "order_help_recovery_description",
    defaultMessage:
      "Ponte en contacto con nuestro equipo para ofrecerte una atención personalizada y entender cuál es tu situación."
  },
  order_help_renting_title: {
    id: "order_help_renting_title",
    defaultMessage: "¿Tienes alguna duda?"
  },
  order_help_renting_description: {
    id: "order_help_renting_description",
    defaultMessage:
      "Obtén respuesta a las las preguntas más comunes de los usuarios de Rentik."
  },
  order_help_call: {
    id: "order_help_call",
    defaultMessage: "Llamar"
  },
  order_help_whatsapp: {
    id: "order_help_whatsapp",
    defaultMessage: "Whatsapp"
  },
  order_partial_session_banner: {
    id: "order_partial_session_banner",
    defaultMessage:
      "¡Hola! Estás en una sesión de solo lectura por eso no puedes acceder al resto de secciones."
  },
  order_partial_session_iframe: {
    id: "order_partial_session_iframe",
    defaultMessage:
      "¡Hola! Estás en una sesión de solo lectura. No puedes interacturar con ninguna tarjeta"
  },
  iban_input_title: {
    id: "iban_input_title",
    defaultMessage: "IBAN"
  },
  iban_input_error: {
    id: "iban_input_error",
    defaultMessage:
      "El número no es válido. Asegúrate que el formato es el correcto."
  },
  wrong_IBAN: {
    id: "wrong_IBAN",
    defaultMessage: "IBAN incorrecto"
  },
  iban_input_action: {
    id: "iban_input_action",
    defaultMessage: "Aceptar"
  },
  request_finished_service_goodbye_message: {
    id: "request_finished_service_goodbye_message",
    defaultMessage: "Esperamos que lo hayas disfrutado."
  },
  request_finished_service_back_to_orders: {
    id: "request_finished_service_back_to_orders",
    defaultMessage: "Ver mi pedido"
  },
  request_finished_service_confirmed_message: {
    id: "request_finished_service_confirmed_message",
    defaultMessage:
      "Gracias por confirmarnos que has finalizado el {service_type} de {service_name} que contrataste con {merchant}."
  },
  request_finished_service_confirmed_text: {
    id: "request_finished_service_confirmed_text",
    defaultMessage: "¡Confirmado!"
  },
  verify: {
    id: "verify",
    defaultMessage: "Verificar"
  },
  verifying: {
    id: "verifying",
    defaultMessage: "Verificando..."
  },
  verify_card_flow_card_successfully_verified_modal_with_card: {
    id: "verify_card_flow_card_successfully_verified_modal_with_card",
    defaultMessage: "Tarjeta ····{lastFour} verificada correctamente"
  },
  verify_card_flow_card_successfully_verified_modal_without_card: {
    id: "verify_card_flow_card_successfully_verified_modal_without_card",
    defaultMessage: "Tarjeta verificada correctamente"
  },
  verify_card_flow_card_successfully_verified_modal_message: {
    id: "verify_card_flow_card_successfully_verified_modal_message",
    defaultMessage:
      "¡Muchas gracias! Ya lo tienes todo listo para seguir con tu plan de pagos como hasta ahora."
  },
  verify_card_flow_error_modal_title: {
    id: "verify_card_flow_error_modal_title",
    defaultMessage: "Ha habido un error al verificar la tarjeta"
  },
  verify_card_flow_error_modal_text_1: {
    id: "verify_card_flow_error_modal_text_1",
    defaultMessage:
      "Vuelve a intentarlo o cambia la tarjeta de pago de tu compra."
  },
  verify_card_flow_error_modal_text_2: {
    id: "verify_card_flow_error_modal_text_2",
    defaultMessage:
      "Si lo necesitas, estamos a tu disposición como siempre en el "
  },
  verify_card_flow_error_modal_try_again: {
    id: "verify_card_flow_error_modal_try_again",
    defaultMessage: "Volver a intentar"
  },
  verify_card_flow_mufasa_modal_title: {
    id: "verify_card_flow_mufasa_modal_title",
    defaultMessage: "Por seguridad, introduce los datos de tu tarjeta"
  },
  verify_card_flow_start_verification_modal_content: {
    id: "verify_card_flow_start_verification_modal_content",
    defaultMessage:
      "Así tus pagos mensuales se seguirán haciendo con total normalidad después de la entrada en vigor de la nueva normativa."
  },
  verify_card_flow_start_verification_modal_title: {
    id: "verify_card_flow_start_verification_modal_title",
    defaultMessage: "Verifica esta tarjeta"
  },
  referral_program_faqs: {
    id: "referral_program_faqs",
    defaultMessage: "Preguntas frecuentes"
  },
  referral_program_page_title: {
    id: "referral_program_page_title",
    defaultMessage: "Invitaciones"
  },
  referral_program_share_panel_title: {
    id: "referral_program_share_panel_title",
    defaultMessage: "¿Con quién compartes las cosas buenas?"
  },
  referral_program_share_panel_description: {
    id: "referral_program_share_panel_description",
    defaultMessage:
      "Regala {guest_voucher_amount} para que conozcan SeQura. Te llevarás {host_voucher_amount} cuando <strong>hagan su primera compra de 50 € o más.</strong>"
  },
  referral_program_share_panel_button: {
    id: "referral_program_share_panel_button",
    defaultMessage: "Enviar invitación"
  },
  referral_program_share_panel_terms_and_conditions: {
    id: "referral_program_share_panel_terms_and_conditions",
    defaultMessage: "Términos y condiciones"
  },
  referral_program_guests_panel_title: {
    id: "referral_program_guests_panel_title",
    defaultMessage: "Gracias a ti ya conocen SeQura:"
  },
  referral_program_guests_panel_description: {
    id: "referral_program_guests_panel_description",
    defaultMessage: "Personas por las que te llevas {host_voucher_amount}."
  },
  general_navigation_referral_program: {
    id: "general_navigation_referral_program",
    defaultMessage: "Invitaciones"
  },
  referral_program_how_to_use_discount_action: {
    id: "referral_program_how_to_use_discount_action",
    defaultMessage: "Cómo usar el descuento"
  },
  referral_program_where_to_use_voucher_action: {
    id: "referral_program_where_to_use_voucher_action",
    defaultMessage: "Dónde usar el descuento"
  },
  referral_program_faqs_term_title_1: {
    id: "referral_program_faqs_term_title_1",
    defaultMessage:
      "¿Con qué métodos de pago seQura se puede usar el descuento?"
  },
  referral_program_faqs_term_paragraph_1: {
    id: "referral_program_faqs_term_paragraph_1",
    defaultMessage:
      "Podrás usar el descuento en todos los métodos de pago de SeQura exceptuando el Pago con tarjeta."
  },
  referral_program_faqs_term_title_2: {
    id: "referral_program_faqs_term_title_2",
    defaultMessage: "¿Cómo aplico el descuento en la compra?"
  },
  referral_program_faqs_term_paragraph_2_1: {
    id: "referral_program_faqs_term_paragraph_2_1",
    defaultMessage:
      "El descuento de seQura no se aplica con ningún código, la opción se te ofrecerá automáticamente durante el proceso de compra al reconocerte por tus datos."
  },
  referral_program_faqs_term_paragraph_2_2: {
    id: "referral_program_faqs_term_paragraph_2_2",
    defaultMessage:
      "Recuerda que para que salga la opción de aplicar el descuento, tu compra en la tienda debe ser siempre igual o superior a {amount}"
  },
  referral_program_faqs_term_title_3: {
    id: "referral_program_faqs_term_title_3",
    defaultMessage: "¿En qué momento se aplica el descuento?"
  },
  referral_program_faqs_term_paragraph_3_1: {
    id: "referral_program_faqs_term_paragraph_3_1",
    defaultMessage:
      "En los métodos de pago a plazos el descuento se aplica en el momento de la compra. Por ejemplo, si el primer plazo de tu compra es de {amount1} pagarás solo {amount2} "
  },
  referral_program_faqs_term_paragraph_3_2: {
    id: "referral_program_faqs_term_paragraph_3_2",
    defaultMessage:
      "En cambio en los métodos de Paga Después, el descuento se aplicará en el pago principal. Por ejemplo, si tu compra es de {amount1} y eliges “Paga en {days} días” en el momento de la compra solo pagarás el coste del servicio de {amount2} y a los {days} días pagarás {amount3}."
  },
  referral_program_faqs_term_title_4: {
    id: "referral_program_faqs_term_title_4",
    defaultMessage:
      "¿Qué pasa si no uso todo el descuento o no lo quiero usar en esta compra?"
  },
  referral_program_faqs_term_paragraph_4: {
    id: "referral_program_faqs_term_paragraph_4",
    defaultMessage:
      "Si no utilizas el total del descuento en tu primera compra, lo que te sobre del descuento se guardará para siguientes compras con seQura durante el año actual."
  },
  referral_program_faqs_term_title_5: {
    id: "referral_program_faqs_term_title_5",
    defaultMessage:
      "¿Por qué en la tienda que he elegido no encuentro todos los métodos de pago de seQura?"
  },
  referral_program_faqs_term_paragraph_5: {
    id: "referral_program_faqs_term_paragraph_5",
    defaultMessage:
      "Las tiendas con las que colaboramos eligen qué métodos de pago seQura quieren ofrecer en su web, que no siempre son todos."
  },
  referral_program_faqs_term_title_6: {
    id: "referral_program_faqs_term_title_6",
    defaultMessage:
      "¿Por qué en la tienda que he elegido no encuentro ningún método de pago de seQura?"
  },
  referral_program_faqs_term_paragraph_6_1: {
    id: "referral_program_faqs_term_paragraph_6_1",
    defaultMessage: "Esto puede pasar por varios motivos:"
  },
  referral_program_faqs_term_list_6_1: {
    id: "referral_program_faqs_term_list_6_1",
    defaultMessage:
      "La tienda es un marketplace que vende productos de otras tiendas."
  },
  referral_program_faqs_term_list_6_2: {
    id: "referral_program_faqs_term_list_6_2",
    defaultMessage: "El importe de tu carrito es demasiado bajo"
  },
  referral_program_faqs_term_list_6_3: {
    id: "referral_program_faqs_term_list_6_3",
    defaultMessage: "La tienda y seQura han dejado de colaborar :("
  },
  referral_program_faqs_term_paragraph_6_2: {
    id: "referral_program_faqs_term_paragraph_6_2",
    defaultMessage:
      "Si te encuentras en alguno de estos casos, puedes escribirnos a clientes@sequra.es para ayudarte a comprar con seQura en la tiendas que hayas elegido o en caso de no poder, asesorarte con otras de sectores similares."
  },
  referral_program_faqs_term_title_7: {
    id: "referral_program_faqs_term_title_7",
    defaultMessage: "¿Cómo puedo invitar a seQura?"
  },
  referral_program_faqs_term_paragraph_7: {
    id: "referral_program_faqs_term_paragraph_7",
    defaultMessage:
      "Esta funcionalidad todavía está en versión Beta. Si no encuentras en tu cuenta la página de “Invitaciones” es que aún no está disponible para todas las cuentas."
  },
  referral_program_faqs_term_title_8: {
    id: "referral_program_faqs_term_title_8",
    defaultMessage:
      "¿Cómo puedo saber si alguien ha usado mi invitación a seQura?"
  },
  referral_program_faqs_term_paragraph_8_1: {
    id: "referral_program_faqs_term_paragraph_8_1",
    defaultMessage:
      "Si has mandado una invitación a seQura, podrás saber que ha usado tu invitación cuando hagan su primera compra superior a {amount}."
  },
  referral_program_faqs_term_paragraph_8_2: {
    id: "referral_program_faqs_term_paragraph_8_2",
    defaultMessage:
      "En la página de invitaciones de tu cuenta aparecerá el nombre de tu invitado/a en uno de los avatares de unicornio."
  },
  referral_program_faqs_term_paragraph_8_3: {
    id: "referral_program_faqs_term_paragraph_8_3",
    defaultMessage:
      "Cuando pasen los {days} días y tu descuento se haga efectivo, te enviaremos un email para que lo puedas gastar."
  },
  referral_program_faqs_term_title_9: {
    id: "referral_program_faqs_term_title_9",
    defaultMessage:
      "¿Cuándo recibiré los {amount} de mi invitado/a si ya ha hecho su primera compra?"
  },
  referral_program_faqs_term_paragraph_9_1: {
    id: "referral_program_faqs_term_paragraph_9_1",
    defaultMessage:
      "Recibirás la recompensa de {amount} en tu cuenta de seQura a los {days} días de haberse confirmado la compra de tu invitado/a."
  },
  referral_program_faqs_term_paragraph_9_2: {
    id: "referral_program_faqs_term_paragraph_9_2",
    defaultMessage:
      "Recuerda que para usar tu descuento, solo tendrás que hacer una compra igual superior a {amount} para que te aparezca la opción de aplicarlos en tu compra :)"
  },
  referral_program_terms_and_conditions_action: {
    id: "referral_program_terms_and_conditions_action",
    defaultMessage: "Términos y condiciones"
  },
  referral_program_terms_and_conditions_modal_title: {
    id: "referral_program_terms_and_conditions_modal_title",
    defaultMessage: "Términos y condiciones"
  },
  referral_program_terms_and_conditions_modal_intro: {
    id: "referral_program_terms_and_conditions_modal_intro",
    defaultMessage:
      "El descuento de seQura ha llegado para quedarse, pero como todo lo bueno en esta vida, tiene unas normas para que podamos disfrutarlo de forma responsable :)"
  },
  referral_program_terms_and_conditions_modal_reward_title: {
    id: "referral_program_terms_and_conditions_modal_reward_title",
    defaultMessage: "Recompensa:"
  },
  referral_program_terms_and_conditions_modal_reward_content1: {
    id: "referral_program_terms_and_conditions_modal_reward_content1",
    defaultMessage:
      "Si te han invitado a seQura, una vez hecho el registro cuando hagas tu primera compra con seQura disfrutarás de un descuento de hasta 10 €."
  },
  referral_program_terms_and_conditions_modal_reward_content2: {
    id: "referral_program_terms_and_conditions_modal_reward_content2",
    defaultMessage:
      "Si has invitado a alguien, recibirás 10 € para tus futuras compras con seQura cuando tu invitado/a haga su primera compra superior a 50 €."
  },
  referral_program_terms_and_conditions_modal_expiration_title: {
    id: "referral_program_terms_and_conditions_modal_expiration_title",
    defaultMessage: "Caducidad:"
  },
  referral_program_terms_and_conditions_modal_expiration_content: {
    id: "referral_program_terms_and_conditions_modal_expiration_content",
    defaultMessage:
      "Los descuentos acumulados caducan al finalizar el año, es decir, el 31 de diciembre a las 23:59 h."
  },
  referral_program_terms_and_conditions_modal_participate_title: {
    id: "referral_program_terms_and_conditions_modal_participate_title",
    defaultMessage: "Requisitos para participar:"
  },
  referral_program_terms_and_conditions_modal_participate_requirement1: {
    id: "referral_program_terms_and_conditions_modal_participate_requirement1",
    defaultMessage: "Tener entre 18 y 85 años"
  },
  referral_program_terms_and_conditions_modal_participate_requirement2: {
    id: "referral_program_terms_and_conditions_modal_participate_requirement2",
    defaultMessage: "Residir en España"
  },
  referral_program_terms_and_conditions_modal_participate_requirement3: {
    id: "referral_program_terms_and_conditions_modal_participate_requirement3",
    defaultMessage: "Tener DNI o NIE en vigor"
  },
  referral_program_terms_and_conditions_modal_invite_limit_title: {
    id: "referral_program_terms_and_conditions_modal_invite_limit_title",
    defaultMessage: "Límite de invitaciones:"
  },
  referral_program_terms_and_conditions_modal_invite_limit_content: {
    id: "referral_program_terms_and_conditions_modal_invite_limit_content",
    defaultMessage:
      "Si tienes habilitada la funcionalidad de invitar, no existe ningún límite para invitar y acumular descuentos. Pero nos reservamos el derecho a cancelar las recompensas, el programa de descuentos o desactivar la funcionalidad de tu cuenta si detectamos cualquier tipo de uso fraudulento."
  },
  referral_program_terms_and_conditions_modal_cancellations_title: {
    id: "referral_program_terms_and_conditions_modal_cancellations_title",
    defaultMessage: "Cancelaciones:"
  },
  referral_program_terms_and_conditions_modal_cancellations_content: {
    id: "referral_program_terms_and_conditions_modal_cancellations_content",
    defaultMessage:
      "Si por algún motivo poco común el sistema de seQura cancela la compra se mantendrá el descuento. Si es la persona quien cancela la compra no se devolverá el descuento."
  },
  referral_program_terms_and_conditions_modal_end_content: {
    id: "referral_program_terms_and_conditions_modal_end_content",
    defaultMessage:
      "Hemos hecho este programa con mucho cariño y queremos que lo disfrutes y compartas con tus personas favoritas. Úsalo con responsabilidad y disfrútalo."
  },
  referral_program_terms_and_conditions_modal_link_text: {
    id: "referral_program_terms_and_conditions_modal_link_text",
    defaultMessage: "Puedes ver el documento completo de"
  },
  referral_program_terms_and_conditions_modal_link_tag: {
    id: "referral_program_terms_and_conditions_modal_link_tag",
    defaultMessage: "términos y condiciones aquí."
  },
  referral_program_terms_and_conditions_modal_agreed: {
    id: "referral_program_terms_and_conditions_modal_agreed",
    defaultMessage: "Entendido"
  },
  referral_program_voucher_balance: {
    id: "referral_program_voucher_balance",
    defaultMessage: "Descuento acumulado"
  },
  referral_program_voucher_balance_subtitle: {
    id: "referral_program_voucher_balance_subtitle",
    defaultMessage: "Para próximas compras de 50 € o más"
  },
  referral_program_where_to_use_the_voucher_modal_content_use_your_voucher: {
    id:
      "referral_program_where_to_use_the_voucher_modal_content_use_your_voucher",
    defaultMessage: "Usa tu descuento en:"
  },
  referral_program_where_to_use_the_voucher_modal_content_use_your_voucher_in_ecommerce: {
    id:
      "referral_program_where_to_use_the_voucher_modal_content_use_your_voucher_in_ecommerce",
    defaultMessage: "Tiendas online con SeQura como método de pago"
  },
  referral_program_where_to_use_the_voucher_modal_content_use_your_voucher_in_instore: {
    id:
      "referral_program_where_to_use_the_voucher_modal_content_use_your_voucher_in_instore",
    defaultMessage: "Tiendas físicas con SeQura como método de pago"
  },
  referral_program_where_to_use_the_voucher_modal_content_not_available: {
    id: "referral_program_where_to_use_the_voucher_modal_content_not_available",
    defaultMessage: "No está disponible en:"
  },
  referral_program_where_to_use_the_voucher_modal_content_not_available_dental: {
    id:
      "referral_program_where_to_use_the_voucher_modal_content_not_available_dental",
    defaultMessage: "Servicios dentales"
  },
  referral_program_where_to_use_the_voucher_modal_content_not_available_education: {
    id:
      "referral_program_where_to_use_the_voucher_modal_content_not_available_education",
    defaultMessage: "Cursos formativos"
  },
  referral_program_faqs_modal_close: {
    id: "referral_program_faqs_modal_close",
    defaultMessage: "Entendido"
  },
  referral_program_share_popup_title: {
    id: "referral_program_share_popup_title",
    defaultMessage: "¿Cómo prefieres enviar tu invitación?"
  },
  referral_program_share_popup_by_mail: {
    id: "referral_program_share_popup_by_mail",
    defaultMessage: "Enviar por email"
  },
  referral_program_share_popup_copy_link: {
    id: "referral_program_share_popup_copy_link",
    defaultMessage: "Copiar invitación"
  },
  referral_program_share_popup_copy_link_confirmation: {
    id: "referral_program_share_popup_copy_link_confirmation",
    defaultMessage: "Invitación copiada"
  },
  referral_program_share_popup_by_whatsapp: {
    id: "referral_program_share_popup_by_whatsapp",
    defaultMessage: "Whatsapp"
  },
  referral_program_share_content: {
    id: "referral_program_share_content",
    defaultMessage:
      "Te regalo {hostVoucherAmount} en tu primera compra con seQura. Tienes más de 4000 marcas para elegir 😍"
  },
  referral_program_share_content_subline: {
    id: "referral_program_share_content_subline",
    defaultMessage:
      "Crea una cuenta con mi invitación y prueba la forma más cómoda y segura de pagar {shareUrl}"
  },
  referral_program_countdown_voucher_pending_days: {
    id: "referral_program_countdown_voucher_pending_days",
    defaultMessage: "En {pendingDays, plural, one {# Día} other {# Días}}"
  },
  flexi_conversion_action: {
    id: "flexi_conversion_action",
    defaultMessage: "Reducir cuota de {totalAmount}"
  },
  flexi_conversion_choose_plan_title: {
    id: "flexi_conversion_choose_plan_title",
    defaultMessage: "Reduce la cuota de {totalAmount} a una más cómoda"
  },
  flexi_conversion_info_order_amount_title: {
    id: "flexi_conversion_info_order_amount_title",
    defaultMessage: "Principal restante: "
  },
  flexi_conversion_info_order_next_instalment_claim: {
    id: "flexi_conversion_info_order_next_instalment_claim",
    defaultMessage: "La próxima cuota de {amount} se cobrará el día {date}"
  },
  flexi_conversion_confirmation_plan_title: {
    id: "flexi_conversion_confirmation_plan_title",
    defaultMessage: "Confirma tu nuevo plan"
  },
  flexi_conversion_confirmation_plan_action: {
    id: "flexi_conversion_confirmation_plan_action",
    defaultMessage: "Confirmar cambio de cuota"
  },
  flexi_conversion_confirmation_plan_number_of_instalments: {
    id: "flexi_conversion_confirmation_plan_number_of_instalments",
    defaultMessage: "{instalmentCount} Plazos"
  },
  flexi_conversion_success_modal_title: {
    id: "flexi_conversion_success_modal_title",
    defaultMessage: "Confirmado :) {br} Has reducido la cuota a {amount}/mes"
  },
  flexi_conversion_success_modal_first_claim: {
    id: "flexi_conversion_success_modal_first_claim",
    defaultMessage: "El próximo pago será el "
  },
  flexi_conversion_success_modal_second_claim: {
    id: "flexi_conversion_success_modal_second_claim",
    defaultMessage:
      "Como siempre, estamos a tu disposición para lo que necesites."
  },
  flexi_conversion_success_modal_action: {
    id: "flexi_conversion_success_modal_action",
    defaultMessage: "Hecho"
  },
  flexi_conversion_error_modal_title: {
    id: "flexi_conversion_error_modal_title",
    defaultMessage: "Algo ha fallado"
  },
  flexi_conversion_error_modal_first_claim: {
    id: "flexi_conversion_error_modal_first_claim",
    defaultMessage:
      "Lo sentimos, hemos detectado un problema técnico y no hemos podido completar tu petición de reducir la cuota."
  },
  flexi_conversion_error_modal_second_claim: {
    id: "flexi_conversion_error_modal_second_claim",
    defaultMessage:
      "Por favor, vuelve a intentarlo de nuevo o prueba más tarde si el error persiste."
  },
  flexi_conversion_error_modal_action: {
    id: "flexi_conversion_error_modal_action",
    defaultMessage: "Volver a intentar"
  },
  spanish: {
    id: "spanish",
    defaultMessage: "Español"
  },
  french: {
    id: "french",
    defaultMessage: "Francés"
  },
  portuguese: {
    id: "portuguese",
    defaultMessage: "Portugués"
  },
  italian: {
    id: "italian",
    defaultMessage: "Italiano"
  },
  terms_and_condition_modal_unicorn_greeting: {
    id: "terms_and_condition_modal_unicorn_greeting",
    defaultMessage: "Holi"
  },
  terms_and_condition_modal_title: {
    id: "terms_and_condition_modal_title",
    defaultMessage: "Te damos la bienvenida a tu cuenta"
  },
  terms_and_condition_modal_description: {
    id: "terms_and_condition_modal_description",
    defaultMessage:
      "Aquí podrás estar al día con toda la información relacionada a tu plan de pagos, gestionar tus datos de contacto y tus tarjetas asociadas."
  },
  terms_and_condition_modal_checkbox: {
    id: "terms_and_condition_modal_checkbox",
    defaultMessage: "Entiendo y acepto la {link} de SeQura."
  },
  terms_and_condition_modal_acceptance: {
    id: "terms_and_condition_modal_acceptance",
    defaultMessage:
      "Al hacer clic en “continuar” confirmo y acepto los {link} de SeQura."
  },
  terms_and_condition_modal_privacy_policy_link: {
    id: "terms_and_condition_modal_privacy_policy_link",
    defaultMessage: "política de privacidad"
  },
  terms_and_condition_modal_terms_condition_link: {
    id: "terms_and_condition_modal_terms_condition_link",
    defaultMessage: "términos y condiciones"
  },
  continue: {
    id: "continue",
    defaultMessage: "Continuar"
  },
  data_protection_modal_title: {
    id: "data_protection_modal_title",
    defaultMessage: "Tu decides la información que recibes"
  },
  data_protection_modal_first_check: {
    id: "data_protection_modal_first_check",
    defaultMessage: "Información sobre el estado de mis compras y mis pagos."
  },
  data_protection_modal_first_check_small_text: {
    id: "data_protection_modal_first_check_small_text",
    defaultMessage:
      "Estas comunicaciones son necesarias para mantener el servicio"
  },
  data_protection_modal_second_check: {
    id: "data_protection_modal_second_check",
    defaultMessage:
      "Quiero que <b>SeQura</b> me pueda enviar promociones e información sobre sus productos."
  },
  data_protection_modal_footer_message: {
    id: "data_protection_modal_footer_message",
    defaultMessage: "Puedes cambiar de opinión cuando quieras desde tu perfil."
  },
  confirm_selection: {
    id: "confirm_selection",
    defaultMessage: "Confirmar selección"
  },
  data_protection_description: {
    id: "data_protection_description",
    defaultMessage:
      "Te avisaremos de nuevas funcionalidades de tu cuenta o promociones de tus tiendas."
  },
  data_protection_label: {
    id: "data_protection_label",
    defaultMessage: "Recibir novedades de SeQura y de tus tiendas"
  },
  general_cookies_policy_page_link: {
    id: "general_cookies_policy_page_link",
    defaultMessage: "https://legal.sequra.com/politica-cookies"
  },
  automatic_payment_page_link: {
    id: "automatic_payment_page_link",
    defaultMessage: "https://legal.sequra.com/pago-automatico-con-tarjeta"
  },
  help_customer_service_email: {
    id: "help_customer_service_email",
    defaultMessage: "clientes@sequra.es"
  },
  help_customer_service_phone: {
    id: "help_customer_service_phone",
    defaultMessage: "+34931760008"
  },
  help_customer_service_phone_label: {
    id: "help_customer_service_phone_label",
    defaultMessage: "931 760 008"
  },
  personal_data_consent_link: {
    id: "personal_data_consent_link",
    defaultMessage:
      "https://legal.sequra.com/consentimiento-uso-datos-personales.html"
  },
  cookies_configuration_modal_title: {
    id: "cookies_configuration_modal_title",
    defaultMessage: "Configura tus preferencias"
  },
  cookies_configuration_modal_strict_cookies_title: {
    id: "cookies_configuration_modal_strict_cookies_title",
    defaultMessage: "Estrictamente necesarias"
  },
  cookies_configuration_modal_strict_cookies_info: {
    id: "cookies_configuration_modal_strict_cookies_info",
    defaultMessage: "Estas cookies son necesarias para mantener el servicio."
  },
  cookies_configuration_modal_analysis_cookies_title: {
    id: "cookies_configuration_modal_analysis_cookies_title",
    defaultMessage: "Análisis"
  },
  cookies_configuration_modal_analysis_cookies_info: {
    id: "cookies_configuration_modal_analysis_cookies_info",
    defaultMessage:
      "Estas cookies nos ayudan a medir el tráfico y también a analizar tu comportamiento dentro de nuestra aplicación para mejorar nuestro servicio."
  },
  cookies_configuration_modal_cookies_link: {
    id: "cookies_configuration_modal_cookies_link",
    defaultMessage: "Ver nuestra política de cookies"
  },
  cookies_configuration_modal_cookies_accept_button: {
    id: "cookies_configuration_modal_cookies_accept_button",
    defaultMessage: "Aceptar todas"
  },
  cookies_banner_text: {
    id: "cookies_banner_text",
    defaultMessage:
      'Utilizamos cookies propias y de terceros para ofrecerte la mejor experiencia de usuario posible. Puedes aceptar nuestras cookies haciendo clic en "Aceptar todas" o ajustar tu selección en haciendo clic en "Configurar"'
  },
  cookies_banner_policy_link: {
    id: "cookies_banner_policy_link",
    defaultMessage: "Política de cookies"
  },
  cookies_banner_accept_button: {
    id: "cookies_banner_accept_button",
    defaultMessage: "Aceptar todas"
  },
  cookies_banner_configuration_button: {
    id: "cookies_banner_configuration_button",
    defaultMessage: "Configurar"
  },
  sticky_banner_title: {
    id: "sticky_banner_title",
    defaultMessage: "Verifica tu identidad"
  },
  sticky_banner_subtitle: {
    id: "sticky_banner_subtitle",
    defaultMessage:
      "Para aceptar nuevas compras, por ley necesitamos disponer de una"
  },
  sticky_banner_subtitle_bold_text: {
    id: "sticky_banner_subtitle_bold_text",
    defaultMessage: "foto de tu DNI/NIE vigente."
  },
  sticky_banner_button_text: {
    id: "sticky_banner_button_text",
    defaultMessage: "Verificar identidad"
  },
  sticky_banner_button_tooltip_text: {
    id: "sticky_banner_button_tooltip_text",
    defaultMessage: "Se abrirá una página privada"
  },
  sticky_collapsed_explanatory_text: {
    id: "sticky_collapsed_explanatory_text",
    defaultMessage: "para hacer nuevas compras"
  }
});
